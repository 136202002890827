import * as React from "react"

import Seo from "../components/seo"
import { useEffect, useState } from "react"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return (
      <React.Fragment>
        <Seo title="Loading..." />
        <h1>Loading...</h1>
        <p>Please wait page is loading.</p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </React.Fragment>
  )
}

export default NotFoundPage
